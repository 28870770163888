import NP from "number-precision";
import { T } from "@/locales";
import moment from "moment";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (_, __, index) => index + 1,
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    width: 240,
    fixed: "left",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("库位"),
    dataIndex: "location_number",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("库龄(天)"),
    dataIndex: "stock_in_date",
    width: 120,
    customRender: (value) => (value ? moment().diff(moment(value), "days") : ""),
  },
  {
    title: T("总数量"),
    dataIndex: "remain_quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: T("已分配数量"),
    dataIndex: "assigned_quantity",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "assigned_quantity" },
  },
  {
    title: T("可用数量"),
    dataIndex: "assignable_quantity",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.assigned_quantity < 0) {
        return item.remain_quantity;
      }
      return NP.minus(item.remain_quantity, item.assigned_quantity);
    },
  },
  {
    title: T("质检状态"),
    dataIndex: "status_display",
    width: 100,
    ellipsis: true,
    key: "quality_inspection_status",
  },
  {
    title: T("单位"),
    dataIndex: "material_unit",
    width: 100,
    ellipsis: true,
  },
  {
    title: T("总重量(吨)"),
    dataIndex: "material_weight",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.material_weight) {
        return NP.times(item.remain_quantity, item.material_weight, 0.001);
      }
    },
  },
  {
    title: T("总体积(立方)"),
    dataIndex: "material_volume",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.material_volume) {
        return NP.times(item.remain_quantity, item.material_volume);
      }
    },
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("生产日期"),
    dataIndex: "production_date",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("容器编号"),
    dataIndex: "pallet_number",
    width: 180,
  },
];
